import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/@shared/base-service/base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { MessageService } from 'src/app/@shared/message/message.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebserviceService extends BaseService<any> {

  constructor(public http: HttpClient, public _message: MessageService) { 
    super(http, _message);
    // console.log(this.router);
    
  }
  // public handleError(error: HttpErrorResponse): Observable<never> {
  //   console.log(787879);
  //   if (error.status == 503) {   
  //     console.log(this.router);
         
  //     this.router.navigate(['/service-unavailable']);
  //   }
    
  //   return super.handleError(error);
  // }
}
