import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";

import { MessageService } from "./message/message.service";
import { SharedService } from "./services/shared.service";
import { WebserviceService } from "./services/webservice.service";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from './services/common.service';
import { RouterModule } from '@angular/router';

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { PusherService } from './services/pusher.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LoaderComponent } from '../layout/loader/loader.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ChangePasswordComponent } from '../pages/change-password/change-password.component';
import { StakesComponent } from '../pages/stakes/stakes.component';
import { BetSlipComponent } from '../pages/sports/bet-slip/bet-slip.component';
import { LoginComponent } from '../auth/login/login.component';
import { SignupComponent } from '../auth/signup/signup.component';
import { ResetpassComponent } from '../auth/resetpass/resetpass.component';
import { SafePipe } from './pipes/safe.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SafeURLPipe } from './pipes/safe-url.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeURLPipe,
    LoaderComponent,
    ChangePasswordComponent,
    StakesComponent,
    BetSlipComponent,
    LoginComponent,
    SignupComponent,
    ResetpassComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: false,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    LazyLoadImageModule
  ],
  exports: [
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    ModalModule,
    TabsModule,
    CollapseModule,
    TooltipModule,
    SafeHtmlPipe,
    SafeURLPipe,
    SafePipe,
    LoaderComponent,
    ChangePasswordComponent,
    StakesComponent,
    BetSlipComponent,
    LoginComponent,
    SignupComponent,
    ResetpassComponent,
    LazyLoadImageModule
  ],
  providers: [MessageService, SharedService, WebserviceService, CommonService, {
    provide: ToastrService
  }],
})
export class SharedModule { }
