import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardGuest } from "./auth/guard/auth.guard-guest";
import { MaintenanceModeComponent } from "./auth/maintenance-mode/maintenance-mode.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path:"service-unavailable",
    component: MaintenanceModeComponent
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "guest",
    loadChildren: () =>
      import("./guest/guest.module").then((m) => m.GuestModule),
    canActivate: [AuthGuardGuest],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
